import React from "react"

export default function Error404() {

  return (
    <div>
      Esta pagina no existe D:
    </div>
  )
}
